import {
  Avatar,
  Badge,
  Header as BaseHeader,
  Box,
  Burger,
  Center,
  CloseButton,
  Divider,
  Drawer,
  Flex,
  Group,
  Menu,
  Modal,
  Paper,
  Space,
  Text,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { IconBellRinging, IconBuilding, IconCaretDown, IconHelp, IconKey, IconLogout, IconNotebook, IconPhoto, IconSettings } from "@tabler/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { AuthApi, NotificationApi } from "../../apis";
import { ReactComponent as Golomt } from "../../assets/golomt.svg";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { authChange, authMe } from "../../store/Auth";
import { message } from "../../utils/Message";
import { useScreenDetector } from "../screen-detector";
import { PageLayout } from ".";
import { PinForm } from "./pin-change-form";

type INotification = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  type: string;
  receiverType: string;
  receiverId: string;
  title: string;
  body: string;
  isSeen: boolean;
  seenDate: string | null;
  logo: string;
};

export const Header = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes, theme } = useStyles();
  const [seenType, setSeenType] = useState<"ALL" | "SEEN" | "UNSEEN">("ALL");
  const { notificationTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);
  const [openNofity, setOpenNotify] = useState<boolean>(false);
  const { isMobile, isTablet } = useScreenDetector();
  const [action, setAction] = useState<any[]>([]);

  const dispatch = useDispatch();

  const { data, isLoading } = useSWR<INotification[]>(
    user && accessToken ? `swr.notification.list.${JSON.stringify(accessToken)}` : null,
    async () => {
      try {
        let res;
        if (user && accessToken) res = await NotificationApi.list();
        return res;
      } catch (error: any) {
        message.error(error?.message);
        return null;
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const logout = async () => {
    try {
      await AuthApi.logout();
      dispatch(authChange());
      dispatch(authMe({} as any));
      // window.location.href = "https://cp.dehub.mn";
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const nofity = (item: INotification, index: number) => {
    return (
      <Flex gap={12} key={index} mb={"lg"}>
        <Avatar src={item.logo} size={"lg"} radius={"xl"}>
          <IconPhoto />
        </Avatar>
        <Flex direction="column" w={"100%"} gap={8}>
          <Flex w={"100%"} align="center" justify="space-between">
            <Text color="violet.9" fw={700}>
              {item.title}
            </Text>
            <Text w={200} truncate color="violet" fz={"sm"} fw={600} align="end">
              {notificationTypes?.find((item2: IReference) => item2.code === item.type)?.name || item.type}
            </Text>
          </Flex>
          <>
            <Text fz={"sm"}>{item.body}</Text>
            <Text color="violet" fz={"xs"} fw={700}>
              {dayjs(item.createdAt).format("HH:mm")}
            </Text>
          </>
        </Flex>
      </Flex>
    );
  };

  return (
    <BaseHeader height={{ base: 68, md: 68 }} className={classes.headerWrap}>
      <Group className={classes.header}>
        {!isMobile ? (
          <Flex align={"center"} gap={8}>
            <a href="https://cp.dehub.mn/login" className={classes.logoBox}>
              <Center className={classes.logo}>
                <Golomt />
              </Center>
            </a>
            {!isTablet && (
              <>
                <Box h={"32px"} style={{ borderRight: `1px solid ${theme.colors.golomt[5]}` }}></Box>
                <Text className={classes.moduleText}>САНХҮҮЖИЛТ УДИРДЛАГА</Text>
              </>
            )}
          </Flex>
        ) : (
          <div>
            <Flex p={"xs"}>
              <Burger color="gray" size={"sm"} opened={opened} onClick={() => setOpened(!opened)} />
            </Flex>
          </div>
        )}

        {user && user?.id ? (
          <Menu width={260} position="bottom-end" transition="pop-top-right">
            <>
              <Group>
                {!isTablet && (
                  <>
                    <UnstyledButton>
                      <IconHelp size={20} stroke={1.5} color={theme.colors.gray[9]} />
                    </UnstyledButton>
                    <UnstyledButton>
                      <IconBuilding size={20} stroke={1.5} color={theme.colors.gray[9]} />
                    </UnstyledButton>
                    <UnstyledButton>
                      <IconSettings size={20} stroke={1.5} color={theme.colors.gray[9]} />
                    </UnstyledButton>
                    <UnstyledButton onClick={() => setOpenNotify(true)}>
                      <IconBellRinging size={20} stroke={1.5} color={theme.colors.gray[9]} />
                    </UnstyledButton>
                  </>
                )}
                <Paper radius={100} withBorder>
                  <Avatar src={user.avatar} radius="xl" />
                </Paper>
                <Menu.Target>
                  <UnstyledButton className={classes.user} style={{ flex: 1 }}>
                    <div>
                      <Text size="sm" weight={500} color={theme.colors.gray[9]} tt={"capitalize"}>
                        {user?.lastName ? user?.lastName[0] + ". " : ""} {user?.firstName || "-"}
                      </Text>
                      <Text size="xs" color={theme.colors.gray[7]}>
                        {user?.clientType === "SME" && "Sme, "}
                        {user?.clientType === "ANCHOR" && "Анкор, "}
                        {user?.currentBusiness?.type === "SUPPLIER" && "Нийлүүлэгч"}
                        {user?.currentBusiness?.type === "BUYER" && "Худалдан авагч"}
                      </Text>
                    </div>
                    <IconCaretDown size={14} stroke={1.5} color={theme.colors.gray[7]} />
                  </UnstyledButton>
                </Menu.Target>
              </Group>
            </>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => (window.location.href = "https://cp.dehub.mn/auth/profile/dan")}
                icon={<IconNotebook size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
                Миний мэдээлэл
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setAction(["PIN"]);
                }}
                icon={<IconKey size={20} color={theme.colors.yellow[6]} stroke={1.5} />}>
                Пин код
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item onClick={() => logout()} color="red" icon={<IconLogout size={20} stroke={1.5} />}>
                Гарах
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : null}
      </Group>
      <Drawer
        size={"620px"}
        padding={"md"}
        position="right"
        opened={openNofity}
        onClose={() => {
          setOpenNotify(false);
        }}
        title={
          <Text color="gray.7" fz={"lg"} fw={700}>
            Мэдэгдэл
          </Text>
        }>
        <Flex align="center" justify="space-between">
          <Flex gap={8}>
            <UnstyledButton
              onClick={() => {
                setSeenType("ALL");
              }}>
              <Badge color={seenType === "ALL" ? "violet" : "gray"} size="lg">
                Бүгд
              </Badge>
            </UnstyledButton>
            <UnstyledButton
              onClick={() => {
                setSeenType("SEEN");
              }}>
              <Badge color={seenType === "SEEN" ? "violet" : "gray"} size="lg">
                Уншсан
              </Badge>
            </UnstyledButton>
            <UnstyledButton
              onClick={() => {
                setSeenType("UNSEEN");
              }}>
              <Badge component="a" color={seenType === "UNSEEN" ? "violet" : "gray"} size="lg">
                Уншаагүй
              </Badge>
            </UnstyledButton>
          </Flex>
        </Flex>
        <Space h={"lg"} />

        {!isLoading ? (
          <>
            {data?.length ? (
              data
                ?.filter((item: INotification) => (seenType === "SEEN" ? item.isSeen : seenType === "UNSEEN" ? !item.isSeen : !item.isSeen || item.isSeen))
                .map((item: any, index: number) => {
                  return nofity(item, index);
                })
            ) : (
              <Text align="center" color="dimmed" size="sm">
                Одоогоор мэдэгдэл алга.
              </Text>
            )}
          </>
        ) : (
          <Text align="center" color="dimmed" size="sm">
            уншиж байна,
          </Text>
        )}
      </Drawer>
      <Modal centered withCloseButton={false} opened={action[0] === "PIN"} onClose={() => setAction([])} padding={0}>
        <Paper withBorder>
          <PageLayout title={user?.hasPin ? "Пин код өөрчлөх" : "Пин код үүсгэх"} extra={[<CloseButton onClick={() => setAction([])} key={0} />]}>
            <Divider mb={"sm"} />
            <PinForm onClose={() => setAction([])} isEdit={user?.hasPin || false} />
          </PageLayout>
        </Paper>
      </Modal>
    </BaseHeader>
  );
};

const useStyles = createStyles((theme) => ({
  headerWrap: {
    background: theme.fn.linearGradient(45, theme.colors.golomt[5], theme.colors.golomt[7]),
    paddingBottom: 4,
    borderBottom: 0,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    background: "#EEF9FE",
    height: 64,
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
    gap: "12px",
  },
  logo: {
    height: 30,
  },
  logoText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.white,
  },
  user: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "8px",
    justifyContent: "space-between",
    color: "#fff",
    "&:hover": {
      opacity: 0.4,
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.colors.golomt[5],
    textTransform: "uppercase",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
